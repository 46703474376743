@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 480px) {
  /* Your CSS Here*/
}
html[data-v-4136698a] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  scroll-behavior: smooth;
}
*[data-v-4136698a] {
  margin: 0;
}
*[data-v-4136698a],
*[data-v-4136698a]::before,
*[data-v-4136698a]::after {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
}
button[data-v-4136698a] {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
}
a[data-v-4136698a] {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
html[data-v-4136698a] {
  font-size: 10px;
}
body[data-v-4136698a] {
  font-size: 1.6rem;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
}
.highlight[data-v-4136698a] {
  font-weight: bold;
  color: #ff0033;
}
.fade-enter-active[data-v-4136698a], .fade-leave-active[data-v-4136698a] {
  transition: opacity 0.5s;
}
.fade-enter[data-v-4136698a], .fade-leave-to[data-v-4136698a] {
  opacity: 0;
}
.container[data-v-4136698a] {
  height: auto;
  width: 100%;
  padding: 0 1.5rem;
}
@media (min-width: 767.98px) {
.container[data-v-4136698a] {
    padding: 0 4rem;
    max-width: 1240px;
    margin: auto;
}
}
.layout[data-v-4136698a] {
  padding: 6rem 0 6rem;
  height: auto;
}
@media (min-width: 767.98px) {
.layout[data-v-4136698a] {
    padding-bottom: 15rem;
    height: auto;
}
}
.btn[data-v-4136698a], .btn--active[data-v-4136698a] {
  padding: 1rem 1.6rem;
  border: 1px solid #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: background 0.5s cubic-bezier(0.02, 0.62, 0.41, 0.98);
}
@media (min-width: 1199.98px) {
.btn[data-v-4136698a], .btn--active[data-v-4136698a] {
    padding: 1rem;
}
}
.btn--equal[data-v-4136698a] {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
@media (min-width: 1199.98px) {
.btn--equal[data-v-4136698a] {
    min-width: 90px;
}
}
.btn[data-v-4136698a]:not(.btn--active):hover, .btn--active[data-v-4136698a]:not(.btn--active):hover {
  background: #000000;
  color: #ffffff;
}
.btn--standard[data-v-4136698a] {
  background: #000000;
  color: #ffffff;
}
.btn--inverse[data-v-4136698a] {
  color: #000000;
}
.btn--active[data-v-4136698a] {
  background: #000000;
  color: #ffffff;
}
.section--heading[data-v-4136698a] {
  font-size: 3rem;
  text-align: center;
  padding: 0 0 6rem;
}
.section--sub--heading[data-v-4136698a] {
  width: 80%;
  padding: 0 0 3rem 0;
  margin: 0 auto;
  font-weight: normal;
  text-align: center;
}
@media (max-width: 575.98px) {
.section--sub--heading[data-v-4136698a] {
    width: 100%;
}
}
.error[data-v-4136698a], .error--serious[data-v-4136698a] {
  border: 1px #f00;
  background-color: #fdd;
}
.error--serious[data-v-4136698a] {
  border-width: 3px;
}
.error[data-v-4136698a]:not(.error--inverse):hover, .error--serious[data-v-4136698a]:not(.error--inverse):hover {
  border: 3px solid black;
}
.slider-tooltip[data-v-4136698a] {
  top: -15px !important;
}
.tooltip[data-v-4136698a] {
  display: block !important;
  z-index: 10000;
  width: 400px;
  font-size: 1.4rem;
}
.tooltip .tooltip-inner[data-v-4136698a] {
  background: #f0f0ee;
  color: #404040;
  border-radius: 0px;
  padding: 5px 10px 4px;
}
.tooltip .tooltip-arrow[data-v-4136698a] {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #f5f7fa;
}
.tooltip[x-placement^=top][data-v-4136698a] {
  margin-bottom: 5px;
}
.tooltip[x-placement^=top] .tooltip-arrow[data-v-4136698a] {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=bottom][data-v-4136698a] {
  margin-top: 5px;
}
.tooltip[x-placement^=bottom] .tooltip-arrow[data-v-4136698a] {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=right][data-v-4136698a] {
  margin-left: 5px;
}
.tooltip[x-placement^=right] .tooltip-arrow[data-v-4136698a] {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^=left][data-v-4136698a] {
  margin-right: 5px;
}
.tooltip[x-placement^=left] .tooltip-arrow[data-v-4136698a] {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[aria-hidden=true][data-v-4136698a] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden=false][data-v-4136698a] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.multiselect__tags[data-v-4136698a] {
  padding: 0.5rem 4rem 0.5rem 0.8rem;
  border-radius: 0;
  border: 1px solid #000000;
  font-size: 14px;
  margin-bottom: 1rem;
}
.multiselect__select[data-v-4136698a]::before {
  color: #000000;
  border-color: #000000 transparent transparent;
}
.multiselect__option--highlight[data-v-4136698a] {
  background: #f0f0ee;
  color: #000000;
}
.multiselect__option--selected.multiselect__option--highlight[data-v-4136698a] {
  background: #000000;
}
.multiselect__input[data-v-4136698a],
.multiselect__single[data-v-4136698a] {
  margin-bottom: 0;
}
.multiselect--active[data-v-4136698a] {
  z-index: 3;
}
.multiselect__content-wrapper[data-v-4136698a] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 275px !important;
}
.multiselect__element[data-v-4136698a] {
  font-size: 1.1rem;
}
@media (min-width: 767.98px) {
.multiselect__element[data-v-4136698a] {
    font-size: 1.6rem;
}
}
.multiselect__placeholder[data-v-4136698a] {
  margin-bottom: 0;
}
.multiselect__input[data-v-4136698a], .multiselect__single[data-v-4136698a] {
  line-height: 1;
  vertical-align: middle;
  min-height: initial;
  font-size: 1.2rem;
}
@media (min-width: 767.98px) {
.multiselect__input[data-v-4136698a], .multiselect__single[data-v-4136698a] {
    font-size: 1.6rem;
}
}
.section-heading[data-v-4136698a] {
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1;
}
.flex-align-center[data-v-4136698a] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-error-display input[data-v-4136698a] {
  margin-bottom: 0 !important;
}
.form-error-display textarea[data-v-4136698a] {
  margin-bottom: 0 !important;
}
.form-error-display .multiselect__tags[data-v-4136698a] {
  margin-bottom: 0 !important;
}
.form-error-display .form-error[data-v-4136698a] {
  margin-bottom: 0;
}
@media (min-width: 767.98px) {
.form-error-display .form-error[data-v-4136698a] {
    margin-bottom: 1rem;
}
}
.form-error[data-v-4136698a] {
  color: #f00;
  font-size: 1rem;
}
@media (min-width: 767.98px) {
.form-error[data-v-4136698a] {
    font-size: 1.3rem;
}
}
.divider[data-v-4136698a] {
  width: 100%;
  height: 0.1rem;
  margin: 2rem 0;
  background-color: #c7c7c7;
}
.feedback-question-header[data-v-4136698a] {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: #cedfe9;
  font-size: 1.4rem;
}
@media (min-width: 767.98px) {
.feedback-question-header[data-v-4136698a] {
    margin-bottom: 2rem;
    padding: 2rem 0;
    font-size: 1.6rem;
}
}
.feedback-question-header .section-heading[data-v-4136698a] {
  margin-bottom: 0;
}
.feedback-question-header span[data-v-4136698a] {
  text-transform: lowercase;
}
.access[data-v-4136698a] {
  position: relative;
}
.access .closeVideo[data-v-4136698a] {
  position: absolute;
  top: -1rem;
  right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #5c5c5c;
  color: #000000;
  font-size: 1.8rem;
  cursor: pointer;
}
.faq-list li[data-v-4136698a] {
  list-style: none;
  margin-left: 0;
  padding-left: 1rem;
}
.faq-list > li[data-v-4136698a]:before {
  display: inline-block;
  content: "-";
  width: 1.5rem;
  margin-left: -1rem;
}
.faq-table[data-v-4136698a] {
  display: table;
}
.faq-table.col-2[data-v-4136698a], .faq-table.col-4[data-v-4136698a] {
  width: 100%;
  font-size: 0.9rem;
}
@media (min-width: 767.98px) {
.faq-table.col-2[data-v-4136698a], .faq-table.col-4[data-v-4136698a] {
    font-size: 1.4rem;
}
}
@media (min-width: 1199.98px) {
.faq-table.col-2[data-v-4136698a], .faq-table.col-4[data-v-4136698a] {
    width: 90%;
    font-size: 1.6rem;
}
}
.faq-table--head[data-v-4136698a] {
  display: table-header-group;
}
.faq-table .tr[data-v-4136698a] {
  display: table-row;
}
.faq-table .td[data-v-4136698a], .faq-table .th[data-v-4136698a] {
  display: table-cell;
}
.faq-table .cell-border[data-v-4136698a] {
  border: 1px solid black;
}
.faq-table .th[data-v-4136698a] {
  font-weight: 600;
}
.faq-table .align-center[data-v-4136698a] {
  text-align: center;
}
.faq-table .text-bold[data-v-4136698a] {
  font-weight: 600;
}
.faq-table .table-highlight[data-v-4136698a] {
  background-color: #9bd1d4;
}
.wrapper[data-v-4136698a] {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1rem;
}
.wrapper[data-v-4136698a]:not(:last-of-type) {
  border-bottom: 2px dotted rgba(200, 209, 221, 0.5);
}
.wrapper .slider-wrapper[data-v-4136698a] {
  position: relative;
  height: 65px;
  display: flex;
}
.wrapper .slider-wrapper .left-wrapper[data-v-4136698a] {
  width: 85%;
  height: 100%;
  align-items: center;
}
.wrapper .slider-wrapper .left-wrapper .slider-name[data-v-4136698a] {
  margin-right: 1rem;
  margin-bottom: 2rem;
}
.wrapper .slider-wrapper .left-wrapper .my-slider[data-v-4136698a] {
  padding: 0px !important;
  margin-top: 1rem !important;
}
.wrapper .slider-wrapper .left-wrapper .vue-slider[data-v-4136698a] .vue-slider-process {
  background-color: black;
  border: black solid 1px;
}
.wrapper .slider-wrapper .left-wrapper .vue-slider[data-v-4136698a] .vue-slider-rail {
  background-color: rgba(0, 0, 0, 0.4);
}
.wrapper .slider-wrapper .left-wrapper .vue-slider[data-v-4136698a] .vue-slider-dot-handle {
  background-color: white;
  transition: background 0.3s ease-in-out;
}
.wrapper .slider-wrapper .left-wrapper .vue-slider[data-v-4136698a] .vue-slider-dot-handle:hover {
  background-color: darkgrey;
}
.wrapper .slider-wrapper .right-wrapper[data-v-4136698a] {
  width: 15%;
  margin-left: 2rem;
  position: relative;
}
.wrapper .slider-wrapper .right-wrapper input[data-v-4136698a] {
  margin-top: 1rem;
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  height: 40px;
  border-radius: 0px;
  border: 1px solid #0a0a0a;
  font-size: 1.4rem;
}
.wrapper .slider-wrapper .right-wrapper input[data-v-4136698a]:focus {
  outline: none;
}
.wrapper .slider-wrapper .right-wrapper .input-error[data-v-4136698a] {
  background-color: #fdd;
  border: 1px solid red;
}
.wrapper .slider-wrapper .right-wrapper .unit-text[data-v-4136698a] {
  position: absolute;
  top: 17px;
  right: 7px;
}
.wrapper .error[data-v-4136698a], .wrapper .error--serious[data-v-4136698a] {
  border: 1px solid red;
  height: 37px;
}
.wrapper .error-messages[data-v-4136698a] {
  display: flex;
  flex-direction: column;
}
.wrapper .error-messages small[data-v-4136698a] {
  margin-left: 0.5rem;
  color: red;
}