@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 480px) {
  /* Your CSS Here*/
}
html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
}

button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
}

.highlight {
  font-weight: bold;
  color: #ff0033;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.container {
  height: auto;
  width: 100%;
  padding: 0 1.5rem;
}
@media (min-width: 767.98px) {
  .container {
    padding: 0 4rem;
    max-width: 1240px;
    margin: auto;
  }
}

.layout {
  padding: 6rem 0 6rem;
  height: auto;
}
@media (min-width: 767.98px) {
  .layout {
    padding-bottom: 15rem;
    height: auto;
  }
}

.btn, .btn--active {
  padding: 1rem 1.6rem;
  border: 1px solid #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: background 0.5s cubic-bezier(0.02, 0.62, 0.41, 0.98);
}
@media (min-width: 1199.98px) {
  .btn, .btn--active {
    padding: 1rem;
  }
}
.btn--equal {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
@media (min-width: 1199.98px) {
  .btn--equal {
    min-width: 90px;
  }
}
.btn:not(.btn--active):hover, .btn--active:not(.btn--active):hover {
  background: #000000;
  color: #ffffff;
}
.btn--standard {
  background: #000000;
  color: #ffffff;
}
.btn--inverse {
  color: #000000;
}

.btn--active {
  background: #000000;
  color: #ffffff;
}

.section--heading {
  font-size: 3rem;
  text-align: center;
  padding: 0 0 6rem;
}

.section--sub--heading {
  width: 80%;
  padding: 0 0 3rem 0;
  margin: 0 auto;
  font-weight: normal;
  text-align: center;
}
@media (max-width: 575.98px) {
  .section--sub--heading {
    width: 100%;
  }
}

.error, .error--serious {
  border: 1px #f00;
  background-color: #fdd;
}
.error--serious {
  border-width: 3px;
}
.error:not(.error--inverse):hover, .error--serious:not(.error--inverse):hover {
  border: 3px solid black;
}

.slider-tooltip {
  top: -15px !important;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  width: 400px;
  font-size: 1.4rem;
}

.tooltip .tooltip-inner {
  background: #f0f0ee;
  color: #404040;
  border-radius: 0px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #f5f7fa;
}

.tooltip[x-placement^=top] {
  margin-bottom: 5px;
}

.tooltip[x-placement^=top] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^=bottom] {
  margin-top: 5px;
}

.tooltip[x-placement^=bottom] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^=right] {
  margin-left: 5px;
}

.tooltip[x-placement^=right] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^=left] {
  margin-right: 5px;
}

.tooltip[x-placement^=left] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.multiselect__tags {
  padding: 0.5rem 4rem 0.5rem 0.8rem;
  border-radius: 0;
  border: 1px solid #000000;
  font-size: 14px;
  margin-bottom: 1rem;
}

.multiselect__select::before {
  color: #000000;
  border-color: #000000 transparent transparent;
}

.multiselect__option--highlight {
  background: #f0f0ee;
  color: #000000;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #000000;
}

.multiselect__input,
.multiselect__single {
  margin-bottom: 0;
}

.multiselect--active {
  z-index: 3;
}

.multiselect__content-wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 275px !important;
}

.multiselect__element {
  font-size: 1.1rem;
}
@media (min-width: 767.98px) {
  .multiselect__element {
    font-size: 1.6rem;
  }
}

.multiselect__placeholder {
  margin-bottom: 0;
}

.multiselect__input, .multiselect__single {
  line-height: 1;
  vertical-align: middle;
  min-height: initial;
  font-size: 1.2rem;
}
@media (min-width: 767.98px) {
  .multiselect__input, .multiselect__single {
    font-size: 1.6rem;
  }
}

.section-heading {
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-error-display input {
  margin-bottom: 0 !important;
}
.form-error-display textarea {
  margin-bottom: 0 !important;
}
.form-error-display .multiselect__tags {
  margin-bottom: 0 !important;
}
.form-error-display .form-error {
  margin-bottom: 0;
}
@media (min-width: 767.98px) {
  .form-error-display .form-error {
    margin-bottom: 1rem;
  }
}

.form-error {
  color: #f00;
  font-size: 1rem;
}
@media (min-width: 767.98px) {
  .form-error {
    font-size: 1.3rem;
  }
}

.divider {
  width: 100%;
  height: 0.1rem;
  margin: 2rem 0;
  background-color: #c7c7c7;
}

.feedback-question-header {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: #cedfe9;
  font-size: 1.4rem;
}
@media (min-width: 767.98px) {
  .feedback-question-header {
    margin-bottom: 2rem;
    padding: 2rem 0;
    font-size: 1.6rem;
  }
}
.feedback-question-header .section-heading {
  margin-bottom: 0;
}
.feedback-question-header span {
  text-transform: lowercase;
}

.access {
  position: relative;
}
.access .closeVideo {
  position: absolute;
  top: -1rem;
  right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #5c5c5c;
  color: #000000;
  font-size: 1.8rem;
  cursor: pointer;
}

.faq-list li {
  list-style: none;
  margin-left: 0;
  padding-left: 1rem;
}
.faq-list > li:before {
  display: inline-block;
  content: "-";
  width: 1.5rem;
  margin-left: -1rem;
}

.faq-table {
  display: table;
}
.faq-table.col-2, .faq-table.col-4 {
  width: 100%;
  font-size: 0.9rem;
}
@media (min-width: 767.98px) {
  .faq-table.col-2, .faq-table.col-4 {
    font-size: 1.4rem;
  }
}
@media (min-width: 1199.98px) {
  .faq-table.col-2, .faq-table.col-4 {
    width: 90%;
    font-size: 1.6rem;
  }
}
.faq-table--head {
  display: table-header-group;
}
.faq-table .tr {
  display: table-row;
}
.faq-table .td, .faq-table .th {
  display: table-cell;
}
.faq-table .cell-border {
  border: 1px solid black;
}
.faq-table .th {
  font-weight: 600;
}
.faq-table .align-center {
  text-align: center;
}
.faq-table .text-bold {
  font-weight: 600;
}
.faq-table .table-highlight {
  background-color: #9bd1d4;
}

.pretty * {
  box-sizing: border-box;
}

.pretty input:not([type=checkbox]):not([type=radio]) {
  display: none;
}

.pretty {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1;
}
.pretty input {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 1em;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.pretty .state label {
  position: initial;
  display: inline-block;
  font-weight: normal;
  margin: 0;
  text-indent: 1.5em;
  min-width: calc(1em + 2px);
}
.pretty .state label:before, .pretty .state label:after {
  content: "";
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  display: block;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid transparent;
  z-index: 0;
  position: absolute;
  left: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  background-color: transparent;
}
.pretty .state label:before {
  border-color: #000000;
}
.pretty .state.p-is-hover, .pretty .state.p-is-indeterminate {
  display: none;
}

@-webkit-keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0);
  }
}
@-webkit-keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7);
  }
  38% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1);
  }
  55% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    transform: scale(1.5);
  }
  72% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    transform: scale(1);
  }
  81% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    transform: scale(1.24);
  }
  89% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    transform: scale(1);
  }
  95% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    transform: scale(1.04);
  }
  100% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    transform: scale(1);
  }
}
@keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7);
  }
  38% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1);
  }
  55% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    transform: scale(1.5);
  }
  72% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    transform: scale(1);
  }
  81% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    transform: scale(1.24);
  }
  89% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    transform: scale(1);
  }
  95% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    transform: scale(1.04);
  }
  100% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    transform: scale(1);
  }
}
@-webkit-keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-200px) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) rotate(0);
  }
}
@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-200px) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) rotate(0);
  }
}
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px black;
  }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(0, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px black;
  }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(0, 0, 0, 0);
  }
}
.pretty.p-default.p-fill .state label:after {
  transform: scale(1);
}

.pretty.p-default .state label:after {
  transform: scale(0.6);
}
.pretty.p-default input:checked ~ .state label:after {
  background-color: #000000 !important;
}

.pretty.p-default.p-thick .state label:before, .pretty.p-default.p-thick .state label:after {
  border-width: 0.1428571429em;
}
.pretty.p-default.p-thick .state label:after {
  transform: scale(0.4) !important;
}

.pretty.p-icon .state .icon {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0;
}
.pretty.p-icon .state .icon:before {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.pretty.p-icon input:checked ~ .state .icon {
  opacity: 1;
}
.pretty.p-icon input:checked ~ .state label:before {
  border-color: #5a656b;
}

.pretty.p-svg .state .svg {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0;
}
.pretty.p-svg .state svg {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.pretty.p-svg input:checked ~ .state .svg {
  opacity: 1;
}

.pretty.p-image .state img {
  opacity: 0;
  position: absolute;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  top: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  left: 0;
  z-index: 0;
  text-align: center;
  line-height: normal;
  transform: scale(0.8);
}
.pretty.p-image input:checked ~ .state img {
  opacity: 1;
}

.pretty.p-switch input {
  min-width: 2em;
}
.pretty.p-switch .state {
  position: relative;
}
.pretty.p-switch .state:before {
  content: "";
  border: 1px solid #000000;
  border-radius: 60px;
  width: 2em;
  box-sizing: unset;
  height: calc(1em + 2px);
  position: absolute;
  top: 0;
  top: calc((0% - (100% - 1em)) - 16%);
  z-index: 0;
  transition: all 0.5s ease;
}
.pretty.p-switch .state label {
  text-indent: 2.5em;
}
.pretty.p-switch .state label:before, .pretty.p-switch .state label:after {
  transition: all 0.5s ease;
  border-radius: 100%;
  left: 0;
  border-color: transparent;
  transform: scale(0.8);
}
.pretty.p-switch .state label:after {
  background-color: #000000 !important;
}
.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b;
}
.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0;
}
.pretty.p-switch input:checked ~ .state label:after {
  background-color: #5a656b !important;
  left: 1em;
}

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}
.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0;
}
.pretty.p-switch.p-fill input:checked ~ .state label:after {
  background-color: #fff !important;
  left: 1em;
}

.pretty.p-switch.p-slim .state:before {
  height: 0.1em;
  background: #000000 !important;
  top: calc(50% - 0.1em);
}
.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none;
}
.pretty.p-has-hover input:hover ~ .state.p-is-hover {
  display: block;
}
.pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
  display: block;
}

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0px 0px 3px 0px #bdc3c7;
}

.pretty.p-has-indeterminate input[type=checkbox]:indeterminate ~ .state:not(.p-is-indeterminate) {
  display: none;
}
.pretty.p-has-indeterminate input[type=checkbox]:indeterminate ~ .state.p-is-indeterminate {
  display: block;
}
.pretty.p-has-indeterminate input[type=checkbox]:indeterminate ~ .state.p-is-indeterminate .icon {
  display: block;
  opacity: 1;
}

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none;
}
.pretty.p-toggle .state.p-off,
.pretty.p-toggle .state .icon,
.pretty.p-toggle .state .svg,
.pretty.p-toggle .state img {
  opacity: 1;
  display: inherit;
}
.pretty.p-toggle .state.p-off .icon {
  color: #000000;
}
.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit;
}
.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none;
}

.pretty.p-plain input:checked ~ .state label:before, .pretty.p-plain.p-toggle .state label:before {
  content: none;
}
.pretty.p-plain.p-plain .icon {
  transform: scale(1.1);
}

.pretty.p-round .state label:before, .pretty.p-round .state label:after {
  border-radius: 100%;
}
.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden;
}
.pretty.p-round.p-icon .state .icon:before {
  transform: scale(0.8);
}

.pretty.p-curve .state label:before, .pretty.p-curve .state label:after {
  border-radius: 20%;
}

.pretty.p-smooth label:before,
.pretty.p-smooth label:after,
.pretty.p-smooth .icon,
.pretty.p-smooth .svg {
  transition: all 0.5s ease;
}
.pretty.p-smooth input:checked + .state label:after {
  transition: all 0.3s ease;
}
.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
  -webkit-animation: zoom 0.2s ease;
          animation: zoom 0.2s ease;
}
.pretty.p-smooth.p-default input:checked + .state label:after {
  -webkit-animation: zoom 0.2s ease;
          animation: zoom 0.2s ease;
}
.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: "";
  transform: scale(0);
  transition: all 0.5s ease;
}

.pretty.p-tada:not(.p-default) input:checked + .state .icon,
.pretty.p-tada:not(.p-default) input:checked + .state .svg,
.pretty.p-tada:not(.p-default) input:checked + .state img,
.pretty.p-tada:not(.p-default) input:checked + .state label:before,
.pretty.p-tada:not(.p-default) input:checked + .state label:after {
  -webkit-animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
          animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  opacity: 1;
}

.pretty.p-jelly:not(.p-default) input:checked + .state .icon,
.pretty.p-jelly:not(.p-default) input:checked + .state .svg,
.pretty.p-jelly:not(.p-default) input:checked + .state img,
.pretty.p-jelly:not(.p-default) input:checked + .state label:before,
.pretty.p-jelly:not(.p-default) input:checked + .state label:after {
  -webkit-animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: transparent;
}

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon,
.pretty.p-rotate:not(.p-default) input:checked ~ .state .svg,
.pretty.p-rotate:not(.p-default) input:checked ~ .state img,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:after {
  -webkit-animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: transparent;
}

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  -webkit-animation: pulse 1s;
          animation: pulse 1s;
}

.pretty input[disabled] {
  cursor: not-allowed;
  display: none;
}
.pretty input[disabled] ~ * {
  opacity: 0.5;
}

.pretty.p-locked input {
  display: none;
  cursor: not-allowed;
}

.pretty input:checked ~ .state.p-primary label:after, .pretty.p-toggle .state.p-primary label:after {
  background-color: #428bca !important;
}
.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg, .pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-primary-o label:before, .pretty.p-toggle .state.p-primary-o label:before {
  border-color: #428bca;
}
.pretty input:checked ~ .state.p-primary-o label:after, .pretty.p-toggle .state.p-primary-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-primary-o .icon,
.pretty input:checked ~ .state.p-primary-o .svg,
.pretty input:checked ~ .state.p-primary-o svg, .pretty.p-toggle .state.p-primary-o .icon,
.pretty.p-toggle .state.p-primary-o .svg,
.pretty.p-toggle .state.p-primary-o svg {
  color: #428bca;
  stroke: #428bca;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #428bca !important;
}
.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #428bca;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #428bca !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: #245682;
  background-color: #245682 !important;
}
.pretty input:checked ~ .state.p-info label:after, .pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important;
}
.pretty input:checked ~ .state.p-info .icon,
.pretty input:checked ~ .state.p-info .svg, .pretty.p-toggle .state.p-info .icon,
.pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-info-o label:before, .pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de;
}
.pretty input:checked ~ .state.p-info-o label:after, .pretty.p-toggle .state.p-info-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-info-o .icon,
.pretty input:checked ~ .state.p-info-o .svg,
.pretty input:checked ~ .state.p-info-o svg, .pretty.p-toggle .state.p-info-o .icon,
.pretty.p-toggle .state.p-info-o .svg,
.pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important;
}
.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important;
}
.pretty input:checked ~ .state.p-success label:after, .pretty.p-toggle .state.p-success label:after {
  background-color: #5cb85c !important;
}
.pretty input:checked ~ .state.p-success .icon,
.pretty input:checked ~ .state.p-success .svg, .pretty.p-toggle .state.p-success .icon,
.pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-success-o label:before, .pretty.p-toggle .state.p-success-o label:before {
  border-color: #5cb85c;
}
.pretty input:checked ~ .state.p-success-o label:after, .pretty.p-toggle .state.p-success-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-success-o .icon,
.pretty input:checked ~ .state.p-success-o .svg,
.pretty input:checked ~ .state.p-success-o svg, .pretty.p-toggle .state.p-success-o .icon,
.pretty.p-toggle .state.p-success-o .svg,
.pretty.p-toggle .state.p-success-o svg {
  color: #5cb85c;
  stroke: #5cb85c;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #5cb85c !important;
}
.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #5cb85c;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #5cb85c !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: #357935;
  background-color: #357935 !important;
}
.pretty input:checked ~ .state.p-warning label:after, .pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important;
}
.pretty input:checked ~ .state.p-warning .icon,
.pretty input:checked ~ .state.p-warning .svg, .pretty.p-toggle .state.p-warning .icon,
.pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-warning-o label:before, .pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e;
}
.pretty input:checked ~ .state.p-warning-o label:after, .pretty.p-toggle .state.p-warning-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-warning-o .icon,
.pretty input:checked ~ .state.p-warning-o .svg,
.pretty input:checked ~ .state.p-warning-o svg, .pretty.p-toggle .state.p-warning-o .icon,
.pretty.p-toggle .state.p-warning-o .svg,
.pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important;
}
.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important;
}
.pretty input:checked ~ .state.p-danger label:after, .pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important;
}
.pretty input:checked ~ .state.p-danger .icon,
.pretty input:checked ~ .state.p-danger .svg, .pretty.p-toggle .state.p-danger .icon,
.pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff;
}
.pretty input:checked ~ .state.p-danger-o label:before, .pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f;
}
.pretty input:checked ~ .state.p-danger-o label:after, .pretty.p-toggle .state.p-danger-o label:after {
  background-color: transparent;
}
.pretty input:checked ~ .state.p-danger-o .icon,
.pretty input:checked ~ .state.p-danger-o .svg,
.pretty input:checked ~ .state.p-danger-o svg, .pretty.p-toggle .state.p-danger-o .icon,
.pretty.p-toggle .state.p-danger-o .svg,
.pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important;
}
.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f;
}
.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important;
}
.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important;
}

.pretty.p-bigger label:before,
.pretty.p-bigger label:after,
.pretty.p-bigger .icon,
.pretty.p-bigger .svg,
.pretty.p-bigger .img {
  font-size: 1.2em !important;
  top: calc(0% - (100% - 1em) - 35%) !important;
}
.pretty.p-bigger label {
  text-indent: 1.7em;
}

@media print {
  .pretty .state:before,
.pretty .state label:before,
.pretty .state label:after,
.pretty .state .icon {
    color-adjust: exact;
    /* stylelint-disable */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}